<template>
    <div class="Refactorinfocus">
        <div class="refa_Box">
            <div class="div_box">
                <div class="kehu_ul_div">
                    <ul class="clearfix kehu_ul">
                        <li class="fl kehu_ul_li">
                            <div class="li_div_one margin30">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他做过的</p>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item,index) in forms.p1.values" :key="index">
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index+1}}、{{item.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_one">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他担心的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p2')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index1==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item1,index1) in forms.p2.values" :key="index1"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index1+1}}、{{item1.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="fl kehu_ul_li">
                            <div class="li_div_two borderTop">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我想到的</p>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item2,index2) in forms.p3.values" :key="index2"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index2+1}}、{{item2.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_two">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我听见的</p>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item3,index3) in forms.p4.values" :key="index3"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index3+1}}、{{item3.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_two borderBottom">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我看见的</p>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item4,index4) in forms.p5.values" :key="index4"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index4+1}}、{{item4.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="fl kehu_ul_li">
                            <div class="li_div_one margin30">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他兴奋的</p>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index5==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item5,index5) in forms.p6.values" :key="index5"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index5+1}}、{{item5.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_one">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他说过的</p>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index6==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item6,index6) in forms.p7.values" :key="index6"
                                            >
                                                <div class="conte_div_one">
                                                    <span>
                                                        {{index6+1}}、{{item6.value}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['searchVals'],
    data() {
        return {
            form:{},
            forms:{
                p1:{
                    values:[]
                },
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                },
                p4:{
                    values:[]
                },
                p5:{
                    values:[]
                },
                p6:{
                    values:[]
                },
                p7:{
                    values:[]
                }
            }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects3({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.form
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveCGJD.css';
</style>
<style>
.refact_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.refact_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.refact_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.refact_popup .el-form-item__content{
    margin-left: 0!important;
}
.refact_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.refact_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.refact_popup .el-dialog__footer{
    text-align: center;
}
.refact_popup .el-button--mini{
    width: 80px;
}
.ri_div .el-button--mini{
    padding: 5px 15px;
}
.ri_div .el-button--danger.is-plain{
    border-color: #999;
    color: #333;
    background: transparent;
}
.ri_div .el-button--danger.is-plain:hover {
    /* background: #f31b1b; */
    border-color: #ed4848;
    color: #f31b1b;
}
</style>